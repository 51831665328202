
// [data-bs-theme="dark"] {
//     .auth-logo{
//         .auth-logo-dark{
//             display: none;
//         }

//         .auth-logo-light{
//             display: block;
//         }
//     }
// }

// Chat Page
.replyCard{
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid $border-color;
    overflow: hidden;
    opacity: 0;
    bottom: 0;
    border-radius: 0;
    transition: all 0.4s;

    @media (max-width: 991.98px) {
        bottom: -12px;
    }

    &.show{
        transform: translateY(-88px);
        opacity: 1;

        @media (max-width: 991.98px) {
            transform: translateY(-83px);
        }
    }
}

.replymessage-block{
    padding: 12px 20px;
    margin-bottom: 8px;
    text-align: left;
    border-radius: 4px;
    background-color: rgba($primary, 0.1);
    border-left: 2px solid rgba($primary, 1);

    .conversation-name{
        color: rgba($primary, 1);
        font-size: 14px;
    }
}

.chat-welcome-section {
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;
    // background-color: var(--bs-card-bg);
}

// Emoji Picker
.emoji-mart{
  position: absolute;
  bottom: 30px;
  left: 0;
}
.emoji-mart-preview{
  display: none;
}

// Accordion
.accordion-button{
    color: var(--bs-body-color);
}