/* ==============
  Email
===================*/
.email-leftbar {
    width: 240px;
    float: left;
    padding: 20px;
    border-radius: 5px;
}

.email-rightbar {
    margin-left: 245px;
}

.chat-user-box {
    p.user-title {
        color: var(--#{$prefix}body-color);
        font-weight: 500;
    }

    p {
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    .email-leftbar {
        float: none;
        width: 100%;
    }

    .email-rightbar {
        margin: 0;
    }
}

.mail-list {
    a {
        display: block;
        color: var(--#{$prefix}body-color);
        line-height: 24px;
        padding: 8px 5px;

        &.active {
            color: $danger !important;
            font-weight: 600;
        }
    }
}

.message-list {
    display: block;
    padding-left: 0;

    li {
        position: relative;
        display: block;
        height: 50px;
        line-height: 50px;
        cursor: default;
        transition-duration: 0.3s;

        a {
            color: var(--#{$prefix}body-color);
        }

        &:hover {
            background: var(--#{$prefix}tertiary-bg);
            transition-duration: 0.05s;
        }

        .col-mail {
            float: left;
            position: relative;
        }

        .col-mail-1 {
            width: 260px;

            .star-toggle,
            .checkbox-wrapper-mail,
            .dot {
                display: block;
                float: left;
            }

            .dot {
                border: 4px solid transparent;
                border-radius: 100px;
                margin: 22px 26px 0;
                height: 0;
                width: 0;
                line-height: 0;
                font-size: 0;
            }

            .checkbox-wrapper-mail {
                margin: 15px 10px 0 20px;
            }

            .star-toggle {
                margin-top: 18px;
                margin-left: 5px;
            }

            .title {
                position: absolute;
                top: 0;
                left: 90px;
                right: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 0;
            }
        }

        .col-mail-2 {
            position: absolute;
            top: 0;
            left: 260px;
            right: 0;
            bottom: 0;

            .subject,
            .date {
                position: absolute;
                top: 0;
            }

            .subject {
                left: 0;
                right: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @media (max-width: 1199.98px) {
                    right: 130px;
                }
            }

            .date {
                right: 0;
                width: 170px;
                padding-left: 80px;

                @media (max-width: 1199.98px) {
                    padding-left: 16px;
                    width: 120px;
                }
            }
        }

        &.active,
        &.active:hover {
            box-shadow: inset 3px 0 0 $primary;
        }

        &.unread {
            background-color: var(--#{$prefix}tertiary-bg);
            font-weight: 600;
            color: var(--#{$prefix}body-color);

            a {
                color: var(--#{$prefix}body-color);
                font-weight: 600;
            }
        }
    }

    .checkbox-wrapper-mail {
        cursor: pointer;
        height: 20px;
        width: 20px;
        position: relative;
        display: inline-block;
        box-shadow: inset 0 0 0 1px  var(--#{$prefix}gray-400);
        border-radius: 1px;

        input {
            opacity: 0;
            cursor: pointer;
        }

        input:checked~label {
            opacity: 0.9;
        }

        label {
            position: absolute;
            height: 20px;
            width: 20px;
            left: 0;
            cursor: pointer;
            opacity: 0;
            margin-bottom: 0;
            transition-duration: 0.05s;
            top: 0;

            &:before {
                content: "\F012C";
                font-family: "Material Design Icons";
                top: 0;
                height: 20px;
                color: var(--#{$prefix}body-color);
                width: 20px;
                position: absolute;
                margin-top: -16px;
                left: 4px;
                font-size: 13px;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .message-list li .col-mail-1 {
        width: 200px;
    }
}


// // dark
// [data-bs-theme="dark"] {
//     .message-list {
//         li {

//             &:hover,
//             &.unread {
//                 background: var(--#{$prefix}input-bg);
//             }

//             &.unread {

//                 .date,
//                 span {
//                     color: var(--#{$prefix}gray-400);
//                 }
//             }

//             a {
//                 color: var(--#{$prefix}gray-400);
//             }
//         }
//     }

//     .mail-list {
//         a {
//             color: var(--#{$prefix}gray-400);
//         }
//     }
// }