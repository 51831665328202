//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  // color: $card-title-desc;
  margin-bottom: 24px;
}

.card-height-100 {
  height: calc(100% - #{$grid-gutter-width});
}
