/*
Template Name: Samply - Admin & Dashboard Template
Author: Pichforest
Version: 2.0.0
Website: https://Pichforest.com/
Contact: support@Pichforest.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins";

// @import "theme-dark";
// @import "theme-light";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/root";
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/team";
@import "custom/pages/gallery";


@import "custom";

// rtl
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/pages-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/text-rtl";



