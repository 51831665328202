/*
Template Name: Samply - Admin & Dashboard Template
Author: Pichforest
Version: 2.0.0
Website: https://Pichforest.com/
Contact: Pichforest@gmail.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";
