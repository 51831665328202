//
// _alert.scss
//

.alert-dismissible {
    .btn-close {
        font-size: 10px;
        padding: $alert-padding-y * 1.6 $alert-padding-x;
    }
}

.alert-dismissible {
    .btn-close {
        background: 0 0 !important;

        &::after {
            content: "\f0156" !important;
            font-size: 18px;
            line-height: 15px;
            font-family: "Material Design Icons" !important;
        }
    }
}


// Alert example loop

@each $color,
$value in $theme-colors {
    .alert {
        &.alert-#{$color} {
            .btn-close::after {
                color: $value;
            }
        }

    }
}