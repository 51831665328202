/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/*Leaflet Map Css*/
@import 'leaflet/dist/leaflet.css';


/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.select2-dropdown{
    border: 1px solid grey !important;
    box-shadow: 0px 0px 1px 1px #888888 !important;
    margin-top: 6px ;
}

.fixedSelect2Box .select2-selection {
    font-size: smaller;
}

.form-control{
    border:1px solid #d0d0d0 !important;
}

.form-select{
    border:1px solid #d0d0d0 !important;
}
.form-check-input{
    border:1px solid #919191 !important;
}